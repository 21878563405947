<template>
  <div class="col-12 card card-body">
    <div class="col-12 table-responsive">
        <div class="col-12 text-center g" v-if="loading">
            <img src="/files/loading.svg" alt="">
        </div>
        <div class="col-12 col-lg-4 g">
            <div class="card card-body">
                <div class="form-group">
                  <label for="">الكود</label>
                  <input type="text"
                    class="form-control" v-model="code">
                </div>
                <div class="form-group">
                  <label for="">هيخصم كام؟</label>
                  <input type="number"
                    class="form-control" v-model="amount">
                </div>
                <div class="form-group">
                  <label for="">المحاولات</label>
                  <input type="number"
                    class="form-control" v-model="left">
                </div>
                <button class="btn btn-primary" @click="create()">انشاء الكود</button>
            </div>
        </div>
        <table class="table table-sm table-hover table-bordered">
            <thead>
                <th>
                    الكود
                </th>
                <th>
                    المحاولات المتبقة
                </th>
                <th>
                    هيخصم كام
                </th>
                <th>
                    من استعمل الكود
                </th>
                <th>
                    خيارات
                </th>
            </thead>
            <tbody>
                <tr v-for="message in messages" :key="message._id">
                    <td>
                        {{ message.code }}
                    </td>
                    <td>
                        {{ message.left }}
                    </td>
                    <td>
                        {{ message.amount }}
                    </td>
                    <td>
                        <a href="javascript:;" @click="$router.push('/orders#' + message.code)">عرض</a>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-danger btn-block" @click="del(message.code)">
                            <i class="fa fa-trash"></i>
                            حذف الكود
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-12">
        <button class="btn btn-danger" @click="page--; getArchive()" v-if=" page > 0 && !loading">الصفحة السابقة <i class="fa fa-arrow-right"></i></button>&nbsp;
        <button class="btn btn-primary" @click="page++; getArchive()" v-if="messages.length > 98 && !loading">الصفحة التالية <i class="fa fa-arrow-left"></i></button>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            messages: [],
            page: 0,
            loading: false,
            q: window.location.hash ? window.location.hash.replace("#", "") : null,
            left: 999,
            code: Math.random().toString().split(".")[1].substring(0,4),
            amount: 100
        }
    },
    created(){
        this.getArchive();
    },
    methods: {
        getArchive(){
            var g = this;
            this.loading = true;
            $.post(api + '/admin/offers', {
                jwt: localStorage.getItem("jwt"),
                page: this.page
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.messages = JSON.parse(JSON.stringify(response.response)).map(function(a){
                        a.edit = false
                        return a
                    })
                }else{
                    g.messages = []
                    alert("حدث خطأ.")
                }
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
        },
        create(){
            var g = this;
            this.loading = true;
            $.post(api + '/admin/offers/add', {
                jwt: localStorage.getItem("jwt"),
                code: this.code,
                left: this.left,
                amount: this.amount
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.getArchive()
                }else{
                    g.messages = []
                    alert("حدث خطأ.")
                }
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
        },
        del(id){
            if(confirm("متاكد؟")){
            var g = this;
            this.loading = true;
            $.post(api + '/admin/offers/delete', {
                jwt: localStorage.getItem("jwt"),
                code: id
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.getArchive()
                }else{
                    g.messages = []
                    alert("حدث خطأ.")
                }
            })
            .fail(function(){
                g.loading = false
                g.messages = []
                alert("حدث خطأ")
            })
            }
        }
    }
}
</script>

<style>

</style>